/**
 * @param params
 * @returns {*|AxiosPromise}
 */
export function sendAccidendLawyerForm(params)
{
    return axios({
        url: '/api/accident-lawyer-form',
        method: 'post',
        data: params,
    });
}
