<template>
  <div v-if="dialogVisible" class="modal-mask">
    <div class="custom-modal-wrapper">
      <div class="custom-modal-container">
        <div class="custom-modal-custom-header">
          <a href="javascript:void(0)" @click="close()">
            <svg style="float: right;" width="18" height="18" viewBox="0 0 18 18" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <path d="M17 1L1 17" stroke="black" stroke-width="2"/>
              <path d="M1 1L17 17" stroke="black" stroke-width="2"/>
            </svg>
          </a>
        </div>
        <div class="custom-modal-body">
          <div class="text-center">
            <span class="heading">
              Заявку створено успішно!
            </span>
          </div>
          <div class="">
            <hr>
          </div>
          <div class="text-center img-block">
            <img src="images/icons/success-icon.svg"/>
          </div>
          <div class="text-center">
            <div class="message">Найближчим часом з клієнтом зв'яжуться наші експерти.</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>



<script>
export default {
  name: 'ModalLawyerSuccess',
  data() {
    return {
      dialogVisible: false
    };
  },
  methods: {
    close(done) {
      this.dialogVisible = false;
      this.$emit('backTomain');
    },
    open: function () {
      this.dialogVisible = true;
    }
  }
};
</script>



<style lang="scss" scoped>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.custom-modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.custom-modal-container {
  .heading {
    font-family: Circe,serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: #24282C;

    @media (max-width: 992px) {
      width: 258px;
      height: 21px;
      font-size: 18px;
      line-height: 20px;
    }
  }
  .img-block {
    padding: 20px 0 40px;
  }
  .message {
    font-family: Circe,serif;
    font-style: normal;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #24282C;

    @media (max-width: 992px) {
      font-size: 16px;
      line-height: 20px;
    }
  }

  @media (max-width: 992px) {
    width:310px;
  }
  width: 440px;
  height: 308px;
  background: #FFFFFF;
  border-radius: 5px;
  margin: 0 auto;
  padding: 20px 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.custom-modal-custom-header h3 {
  margin-top: 0;
  color: #42b983;
}

.custom-modal-body {
  margin: 20px 15px;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .custom-modal-container,
.modal-leave-active .custom-modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

</style>

