<template>
    <div class="container">
        <div class="navsteps">
            <div class="navsteps__item active">
                <div class="number">1</div>
                <div class="descr">Створення заявки</div>
            </div>
            <div class="navsteps__item">
                <div class="number">2</div>
                <div class="descr">Дзвінок клієнту</div>
            </div>
        </div>
        <LawyerAccidentForm />
    </div>
</template>
<script>
import LawyerAccidentForm from '@/views/accidentLawyer/components/LawyerAccidentForm.vue'
export default {
    components: {LawyerAccidentForm},
}
</script>
<style src="../../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
